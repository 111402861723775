import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { basename } from "../config/buildSettings.js";

class InternalHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleNavigateSearch = this.handleNavigateSearch.bind(this);
    this.handleNavigateInternalSearch = this.handleNavigateInternalSearch.bind(
      this
    );
    this.handleNavigateUpload = this.handleNavigateUpload.bind(this);
    this.handleNavigateTestSearch = this.handleNavigateTestSearch.bind(this);
    this.handleNavigateTestUpload = this.handleNavigateTestUpload.bind(this);
    this.handleNavigateRegister = this.handleNavigateRegister.bind(this);
    this.handleNavigateReports = this.handleNavigateReports.bind(this);
    this.handleNavigatePublicKPI = this.handleNavigatePublicKPI.bind(this);
    this.handleNavigateInternalKPI = this.handleNavigateInternalKPI.bind(this);
    this.handleNavigateDateRangeKPI = this.handleNavigateDateRangeKPI.bind(this);
    
    this.handleNavigateExport = this.handleNavigateExport.bind(this);


    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleNavigateSearch(e) {
    localStorage.removeItem("search");
    this.props.history.push({ pathname: "/" });
  }

  handleNavigateInternalSearch(e) {
    this.props.history.push({ pathname: "/internalsearch" });
  }

  handleNavigateUpload(e) {
    this.props.history.push({ pathname: "/upload" });
  }
  
  handleNavigateExport(e) {
    e.preventDefault();
    this.props.history.push({ pathname: "/export" });
  }


  handleNavigateTestSearch(e) {
    this.props.history.push({ pathname: "/test" });
  }

  handleNavigateTestUpload(e) {
    this.props.history.push({ pathname: "/testupload" });
  }


  handleNavigateRegister(e) {
    this.props.history.push({ pathname: "/register" });
  }

  handleNavigateANROnlineUsers(e) {
    this.props.history.push({ pathname: "/anronlineusers" });
  }

  handleNavigateResultsAdmin(e) {
    this.props.history.push({ pathname: "/adminresults" });
  }

  handleNavigateTestResultsAdmin(e) {
    this.props.history.push({ pathname: "/testadminresults" });
  }
  handleNavigateSchoolName(e) {
    this.props.history.push({ pathname: "/schooleditor/prod" });
  }

  handleNavigateTestSchoolName(e) {
    this.props.history.push({ pathname: "/schooleditor/test" });
  }

  handleNavigateReports(Report_ID) {
    this.props.history.push({ pathname: "/reports/" + Report_ID });
  }

  handleNavigatePublicKPI() {
    this.props.history.push({ pathname: "/internalsummary" });
  }

  handleNavigateInternalKPI() {
    this.props.history.push({ pathname: "/kpi" });
  }
  handleNavigateDateRangeKPI() {
    this.props.history.push({ pathname: "/daterangekpi" });
  }

  onLogout(e) {
    this.props.handleLogout();
  }

  renderTitle() {
    if (this.props.location.pathname.search("test") !== -1) {
      return "Lead in School and Child Care Drinking Water Results TEST";
    } else if (this.props.location.pathname.search("internal") !== -1) {
      return "Lead in School and Child Care Drinking Water Results INTERNAL";
    } else {
      return "Lead in School and Child Care Drinking Water Results";
    }
  }

  render() {
    return (
      <span>
        <Container className="top-bar-outer-container">
          <Container className="top-bar-inner-container">
            <Row className="vermont-official-row align-items-center">
              <Col>
                <a
                  rel="noopener noreferrer"
                  alt="Moon over Mountains Logo"
                  href="http://vermont.gov/portal/"
                  target="_blank"
                  className="vermont-official"
                >
                  VERMONT OFFICIAL STATE WEBSITE
                </a>
              </Col>
              <Col>
                <a
                  rel="noopener noreferrer"
                  alt="State of Vermont Link"
                  href="http://vermont.gov/portal/"
                  target="_blank"
                  className="vermont-logo"
                  aria-label="vermont logo"
                >
                  {" "}
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="site-name-outer-container box-shadow">
          <Container className="site-name-inner-container">
            <Row className="site-name-row align-items-center">
              <Col>
                <div className="site-name">STATE OF VERMONT</div>
                <a
                  rel="noopener noreferrer"
                  alt="Site Subname Link"
                  href={basename}
                  className="site-subname"
                >
                  {this.renderTitle()}
                </a>
              </Col>
            </Row>
          </Container>
          <Container className="top-navigation-inner-container">
            <Row className="top-navigation-row align-items-center">
              <Navbar
                className="top-navigation-bar"
                color="#efefe3"
                light
                expand="sm"
              >
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="top-navigation-dropdown"
                    >
                      <DropdownToggle nav caret>
                        SEARCH
                      </DropdownToggle>
                      <DropdownMenu className="top-navigation-dropdown-menu">
                        <DropdownItem
                          onClick={this.handleNavigateInternalSearch}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>INTERNAL</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={this.handleNavigateSearch}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>PUBLIC</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={this.handleNavigateTestSearch}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>TEST</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="top-navigation-dropdown"
                    >
                      <DropdownToggle nav caret>
                        UPLOAD
                      </DropdownToggle>
                      <DropdownMenu className="top-navigation-dropdown-menu">
                        <DropdownItem
                          onClick={this.handleNavigateUpload}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>UPLOAD</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={this.handleNavigateTestUpload}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>TEST UPLOAD</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>


                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="top-navigation-dropdown"
                    >
                      <DropdownToggle nav caret>
                        REPORTS
                      </DropdownToggle>
                      <DropdownMenu className="top-navigation-dropdown-menu">
                        {/* <DropdownItem
                          onClick={() => this.handleNavigateReports(1)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>UNADDRESSED TAPS TEST</NavLink>
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(2)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>UNADDRESSED TAPS</NavLink>
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={() => this.handleNavigateReports(3)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>DATA EXPORT TEST</NavLink>
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(4)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>DATA EXPORT</NavLink>
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={() => this.handleNavigateReports(5)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>DASHBOARD TEST</NavLink> 
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(6)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>DASHBOARD</NavLink>
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={() => this.handleNavigateReports(7)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>REMEDIATION EXPORT TEST</NavLink>
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(8)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>REMEDIATION EXPORT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(9)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>TAP EXPORT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(10)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>PLACES WITH ALL ELEVATED TAPS</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(11)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>RESULTS MISSING INITIAL LAB ID</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(15)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>FLUSH SAMPLES WITH NO VALID FIRST DRAW</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(12)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>PLACES WITH UNRESOLVED EXCEEDANCES</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(14)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>EXCEEDANCE STATUS REPORT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateReports(13)}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>PROVIDERS WITH SAMPLES OVER TIME</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigatePublicKPI()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>PUBLIC SUMMARY</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateInternalKPI()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>INTERNAL KPI</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateDateRangeKPI()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>KPIS FOR DATE RANGE</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      className="top-navigation-dropdown"
                    >
                      <DropdownToggle nav caret>
                        ADMIN
                      </DropdownToggle>
                      <DropdownMenu className="top-navigation-dropdown-menu">
                        <DropdownItem
                          onClick={() => this.handleNavigateANROnlineUsers()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>ANROnline USERS</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateRegister()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>INTERNAL USERS</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateResultsAdmin()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>RESULTS MANAGEMENT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateTestResultsAdmin()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>TEST RESULTS MANAGEMENT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateSchoolName()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>SCHOOL NAME MANAGEMENT</NavLink>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => this.handleNavigateTestSchoolName()}
                          className="top-navigation-dropdown-link"
                        >
                          <NavLink>TEST SCHOOL NAME MANAGEMENT</NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                      <NavLink
                        className="top-navigation-link"
                        onClick={this.handleNavigateExport}
                      >
                        EXPORT DATA
                      </NavLink>
                    </NavItem>
                    <NavItem onClick={this.props.onLogout}>
                      <NavLink className="top-navigation-link">LOG OUT</NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </Row>
          </Container>
        </Container>
        <br />
        <br />
      </span>
    );
  }
}

export default InternalHeader;
