import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Button, Form, Input, Alert, FormGroup, Label } from "reactstrap";
import { base_URL } from "../config/buildSettings.js";
import { apiKey } from "../config/index.js";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

class DateRangeKPI extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.toggleMessageAlert = this.toggleMessageAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
    this.handleCalculate = this.handleCalculate.bind(this);

    this.state = {
      data: {},
      begin_date: moment("2017-01-01"),
      end_date: moment(),
      begin_dateFocused: false,
      end_dateFocused: false,
      loading: false,
      report: "",
      error: "",
      message: "",
      showMessageAlert: false,
      showErrorAlert: false,
      isTest: false,
      isInternal: false,
    };
  }

  componentDidMount() {}

  toggleErrorAlert() {
    this.setState({
      showErrorAlert: !this.state.showErrorAlert,
      error: "",
    });
  }

  toggleMessageAlert() {
    this.setState({
      showMessageAlert: !this.state.showMessageAlert,
      message: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleCalculate(event) {
    event.preventDefault();

    this.fetchData();
  }

  fetchData(event) {
    this.setState({ loading: true });
    let url, useCredentials;

    url = `${base_URL}/api/internal/daterangekpi`;
    useCredentials = "include";

    // console.log(url);
    fetch(url, {
      method: "POST",
      credentials: useCredentials,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: apiKey,
        begin_date: this.state.begin_date,
        end_date: this.state.end_date,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        if (!json || json.length === 0) {
          let message;
          message = "No Data Found";
          this.setState({
            message: message,
            showMessageAlert: true,
            data: {},
            loading: false,
          });

          return;
        }
        console.log(json);
        this.setState({ data: json, loading: false });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          showErrorAlert: true,
          data: {},
          loading: false,
        });
      });
  }

  render() {
    return (
      <span>
        <Container>
          <Form onSubmit={this.handleCalculate}>
            <Row form>
              <Col
                md={{ size: 4 }}
                lg={{ size: 4 }}
                xl={{ size: 3 }}
                style={{ marginBottom: "1rem" }}
              >
                <FormGroup>
                  <Label for="beginDateInput">Sampling Begin Date</Label>
                  <br />
                  <SingleDatePicker
                    date={this.state.begin_date}
                    onDateChange={(begin_date) =>
                      this.setState({ begin_date: begin_date })
                    }
                    focused={this.state.begin_dateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ begin_dateFocused: focused })
                    }
                    isOutsideRange={(day) => false}
                    renderMonthElement={renderMonthElement}
                    numberOfMonths={1}
                    id="beginDateInput"
                    block={true}
                  />
                </FormGroup>
              </Col>
              <Col
                md={{ size: 4 }}
                lg={{ size: 4 }}
                xl={{ size: 3 }}
                style={{ marginBottom: "1rem" }}
              >
                <FormGroup>
                  <Label for="endDateInput">Sampling End Date</Label>
                  <br />
                  <SingleDatePicker
                    date={this.state.end_date}
                    onDateChange={(end_date) =>
                      this.setState({ end_date: end_date })
                    }
                    focused={this.state.end_dateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ end_dateFocused: focused })
                    }
                    isOutsideRange={(day) => false}
                    renderMonthElement={renderMonthElement}
                    numberOfMonths={1}
                    id="endDateInput"
                    block={true}
                  />
                </FormGroup>
              </Col>
              <Col
                md={{ size: 4 }}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button type="submit" color="primary">
                  Calculate
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
          <Container style={{ textAlign: "center" }}>
            <Row>
              <Col>
                <span className="login-spinner">
                  <BounceLoader
                    size={60}
                    sizeUnit={"px"}
                    color={"#36D7B7"}
                    css={override}
                    loading={this.state.loading}
                  />
                </span>
                {this.state.error !== "" && (
                  <Alert
                    color="warning"
                    isOpen={this.state.showErrorAlert}
                    toggle={this.toggleErrorAlert}
                  >
                    {this.state.error}
                  </Alert>
                )}
                {this.state.message !== "" && (
                  <Alert
                    color="info"
                    isOpen={this.state.showMessageAlert}
                    toggle={this.toggleMessageAlert}
                  >
                    {this.state.message}
                  </Alert>
                )}
              </Col>
            </Row>
            <Row>
              {!this.state.loading && this.state.data && this.state.data.length > 0 && (
                <>
                  <Col xl={{ size: "10", offset: "1" }}>
                    <span>
                      <span className="h4">
                        Key Performance Indicators for Sampling between{" "}
                        {moment(this.state.begin_date).format("L")} and{" "}
                        {moment(this.state.end_date).format("L")}
                      </span>
                      <br />
                      <br />
                      <Table bordered className="kpi-table">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "left" }}>Category</th>
                            <th style={{ textAlign: "right" }}>Number</th>
                            <th style={{ textAlign: "left" }}>
                              Percent of Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map(
                            (datum) =>
                              datum.Sequence && (
                                <tr key={datum.Sequence}>
                                  <td style={{ textAlign: "left" }}>
                                    {datum.Sequence + ". " + datum.Indicator}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {Number(datum.Count).toLocaleString(
                                      "EN-us"
                                    )}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {!datum.Denominator.includes("N/A")
                                      ? parseFloat(datum.Rate * 100).toFixed(
                                          0
                                        ) +
                                        "% " +
                                        datum.Denominator
                                      : datum.Denominator}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </Table>
                    </span>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </Container>
      </span>
    );
  }
}

export default DateRangeKPI;

const returnYears = () => {
  let years = [];
  for (let i = moment().year() - 15; i <= moment().year() + 1; i++) {
    years.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return years;
};

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div>
      <Input
        bsSize="sm"
        type="select"
        value={month.month()}
        onChange={(e) => onMonthSelect(month, e.target.value)}
      >
        {moment.months().map((label, value) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Input>
    </div>
    <div>
      <Input
        bsSize="sm"
        type="select"
        value={month.year()}
        onChange={(e) => onYearSelect(month, e.target.value)}
      >
        {returnYears()}
      </Input>
    </div>
  </div>
);
